import React from "react";
import { Link } from "gatsby";
import { PortFolioEntry } from "api/sdk";

const typeLogo = require("../images/type-logo.svg").default;

export interface PortfolioItemProps {
  portfolioEntry: PortFolioEntry;
}

export default (props: PortfolioItemProps) => {
  const { portfolioEntry } = props;

  return !portfolioEntry.inProgress ? (
    <Link
      to={`/portfolio/${portfolioEntry.slug}`}
      className="portfolio-grid__item"
      style={{
        backgroundImage: `url(${portfolioEntry.thumbnail})`,
      }}
    >
      <div className={`portfolio-grid__item__overlay`}>
        <div>{portfolioEntry.name}</div>
      </div>
    </Link>
  ) : (
    <div>
      <div
        className="portfolio-grid__item"
        style={{
          backgroundImage: `url(${portfolioEntry.thumbnail})`,
        }}
      >
        <img
          src={typeLogo}
          alt="Hillary Taylor Interiors"
          className="in_progress_mark"
        />
      </div>
      <h2 className="in_progress_title">
        {portfolioEntry.name} - <em>in progress</em>
      </h2>
    </div>
  );
};
