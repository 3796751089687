import React from "react";
import Button from "./Button";
import { PortFolioEntry } from "api/sdk";
import PortfolioItem from "./PortfolioItem";

const portfolioHeader = require("../images/portfolio-header.svg").default;

export interface PortfolioGridProps {
  portfolioEntries: PortFolioEntry[];
  isSample: Boolean;
}

export default (props: PortfolioGridProps) => {
  const { portfolioEntries } = props;
  return (
    <div className="container--large spaced-50">
      <img className="header-svg" src={portfolioHeader} alt="Portfolio" />

      <div className="portfolio-grid">
        {portfolioEntries.map((entry) => {
          return <PortfolioItem key={entry._id} portfolioEntry={entry} />;
        })}
      </div>
      {props.isSample && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button link="/portfolio" classes="blue">
              View More
            </Button>
          </div>
      )}
    </div>
  );
};
