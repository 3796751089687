import React, { PropsWithChildren } from "react";
import { Link } from "gatsby";

export interface ButtonProps {
  link: string;
  classes: string;
}

export default (props: PropsWithChildren<ButtonProps>) => {
  const { link, classes, children } = props;

  return (
    <Link to={link} className={`button ${classes}`}>
      {children}
    </Link>
  );
};
