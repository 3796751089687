import PortfolioGrid from "components/PortfolioGrid";
import SEO from "components/SEO";
import { HeadFC } from "gatsby";
import Layout from "layout";
import React from "react";
import {
  BasePageContext,
  TemplateHeadProps,
  TemplatePageProps,
} from "utils/types";
const htHomeImg = require("images/hillary-taylor-home.jpg").default;


export default (props: TemplatePageProps<BasePageContext>) => {
  const { appData } = props.pageContext;
  const sortedData = appData.portfolioEntries.slice().sort( (prev, value) => { return prev.order - value.order } )

  return (
    <Layout appData={appData}>
      <PortfolioGrid portfolioEntries={sortedData} isSample={false} />
    </Layout>
  );
};

export const Head = (props: TemplateHeadProps<BasePageContext>) => {
  const { portfolioEntries } = props.pageContext.appData;

  return (
    <SEO
      title="Portfolio"
      ogMeta={{
        image: portfolioEntries[0]?.thumbnail ?? htHomeImg,
      }}
    ></SEO>
  );
};
